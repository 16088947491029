import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Head from 'Components/core/Head';
import PageContent from 'Components/layout/panel/PageContent';
import Calendar from 'Components/trainer/events/Calendar';

export default class TrainerDashboard extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        profile: PropTypes.object.isRequired,
    };

    render() {
        const { location, history } = this.props;
 
        return (
            <StyledComponent 
                className="component-dashboard-admin-page" 
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }]}
                >
                    <Head title="Dashboard" />
                    <Calendar 
                        location={location}
                        history={history}
                    /> 
                </PageContent>
            </StyledComponent>
        );
    }
}
