import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        background-color: ${variables.dpc_backgroundWhite};
        min-height: 100vh;
        
        .trainer-events-calendar {
            margin-top: 2em;
        }
    `;
